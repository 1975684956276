import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

import { uploadBanner, removeBanner } from './newsStorage'
import { createPath } from './misc'

const database = firebase.firestore()
const newsCollection = database.collection('news')

// Metadata for news article
const articleMetadata = {
    viewCount: 0
}

// Use for retrieving metadata of article
const getMetadata = async (id) => {
    const newsReference = newsCollection.doc(id)
    const metadataReference = newsReference.collection('public').doc('metadata')

    // Getting metadata
    const metadataDocument = await metadataReference.get()
    const metadata = metadataDocument.data()

    return metadata
}

// Use for creating news article
const createArticle = async (title, banner, content, author) => {
    const newsReference = newsCollection.doc()
    const metadataReference = newsReference.collection('public').doc('metadata')
    const articleId = newsReference.id

    // Upload banner image for this article
    let bannerMetadata = {url: null, path: null}
    if (banner !== null) bannerMetadata = await uploadBanner(banner)

    // Setup article data
    const data = {
        authorId: author,
        banner: bannerMetadata,
        categoryId: null,
        title: title,
        body: content,
        path: createPath(title.en, "", articleId),
        postDate: firebase.firestore.FieldValue.serverTimestamp()
    }

    // Create document of news article in database
    await database.runTransaction(transaction => {
        try {
            transaction.set(newsReference, data)
            transaction.set(metadataReference, articleMetadata)
            return Promise.resolve()
        } catch (error) {
            return Promise.reject()
        }
    })
    .catch(error => {
        // TODO: handle errors
        console.log(error)
        throw Error(error)
    })
    return `Article has been created!`
}

// Use for updating already exist article's data
const updateArticle = async (id, title, banner, bannerPath, content) => {
    const newsReference = newsCollection.doc(id)
    
    // Data for updating news article
    let data = {
        title: title,
        body: content,
        path: createPath(title.en, "", id)
    }

    // Remove old banner and replace with newer one
    if (banner !== null) {
        removeBanner(bannerPath)
        let bannerMetadata = await uploadBanner(banner)
        data.banner = bannerMetadata
    }

    // Update article data in database
    await database.runTransaction(transaction => {
        try {
            transaction.update(newsReference, data)
            return Promise.resolve()
        } catch(error) {
            return Promise.reject()
        }
    })
    .catch(error => {
        // TODO: handle errors
        console.log(error)
        throw Error(error)
    })
    return `Article with id: ${id} has been updated!`
}

// Use for deleting specific article
const deleteArticle = async (id, bannerPath) => {
    const newsReference = newsCollection.doc(id)
    const metadataReference = newsReference.collection('public').doc('metadata')

    // Remove banner image if exist
    if (bannerPath !== null) {
        await removeBanner(bannerPath).catch(error => {
            // TODO: handle errors
            console.log(error)
            throw Error(error)
        })
    }

    // Remove article data and metadata document
    await database.runTransaction(transaction => {
        try {
            transaction.delete(metadataReference)
            transaction.delete(newsReference)
            return Promise.resolve()
        } catch(error) {
            return Promise.reject()
        }
    })
    .catch(error => {
        // TODO: handle errors
        console.log(error)
        throw Error(error)
    })

    return `Article with id: ${id} has been deleted.`
}

// Use for removing multiple article document
const deleteMultiArticles = async (ids) => {
    let batch = database.batch()
    
    // Reference article document one by one and put in batch transaction to remove
    for (let id of ids) {
        let newsReference = newsCollection.doc(id)
        let metadataReference = newsReference.collection('public').doc('metadata')

        // Add article and news reference into the batch to delete
        batch.delete(metadataReference)
        batch.delete(newsReference)

        // Delete article banner
        let articleDocument = await newsReference.get()
        let article = articleDocument.data()
        let bannerPath = article.banner.path

        if (bannerPath !== null) {
            await removeBanner(bannerPath).catch(error => {
                // TODO: handle errors
                console.log(error)
                throw Error(error)
            })
        }
    }

    // Remove articles in database
    await batch.commit()
    .catch(error => {
        // TODO: handle errors
        console.log(error)
        throw Error(error)
    })

    return `Selected articles have been deleted.`
}

// Use for getting specific article
const getSingleArticle = async (articleId) => {
    const newsReference = newsCollection.doc(articleId)
    
    // Getting data
    let articleDocument = await newsReference.get()
    let article = articleDocument.data()
    let metadata = await getMetadata(articleDocument.id)

    article.metadata = metadata
    article.id = articleId

    return article
}

// Use for getting all articles
const getNewsArticles = async (category=undefined) => {
    let newsSnapshot
    if (category) newsSnapshot = await newsCollection.where('categoryId', '==', category).get()
    else newsSnapshot = await newsCollection.get()

    let newsList = []
    for (let doc of newsSnapshot.docs) {
        let article = doc.data()
        let metadata = await getMetadata(doc.id)
        article.id = doc.id
        article.metadata = metadata
        newsList.push(article)
    }
    return newsList
}

const viewCounter = async (id) => {
    const increment = firebase.firestore.FieldValue.increment(1)
            
    let newsRef = newsCollection.doc(id)
    let metadataRef = newsRef.collection('public').doc('metadata')
    database.runTransaction(async transaction => {
        return transaction.get(newsRef).then(newsDoc => {
            if (!newsDoc.exists) throw "An article does not exist."
            transaction.update(metadataRef, {viewCount: increment})
        })
    })
}

export {
    createArticle,
    updateArticle,
    deleteArticle,
    deleteMultiArticles,
    getSingleArticle,
    getNewsArticles,
    viewCounter
}