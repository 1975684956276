import firebase from 'firebase/app'
import 'firebase/storage'

import { uuidv4 } from './misc'

const storage = firebase.storage()
const newsStorageRef = storage.ref('/imgs/news')

const uploadBanner = async (blob) => {
    const metadata = await newsStorageRef.child(`${uuidv4()}`).put(blob).then(async snapshot => {
        return {
            url: await snapshot.ref.getDownloadURL(),
            path: snapshot.ref.fullPath
        }
    })
    .catch(error => { throw Error(error) })
    return metadata
}

const removeBanner = async (path) => {
    const reference = storage.ref(path)
    await reference.delete().catch(error => { throw Error(error) })
    return `Banner image has been removed`
}

export {
    uploadBanner,
    removeBanner
}