const createPath = (title, prefix="", suffix="") => {
    title = title.toLowerCase()
    if (prefix) title = `${prefix}-${title}`
    if (suffix) title = `${title}-${suffix}`
    let encoded = encodeURIComponent(title)
    return encoded
}

const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, char => {
        var r = Math.random() * 16 | 0, v = char == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
    })
}

export {
    createPath,
    uuidv4
}